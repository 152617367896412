<template>
    <div>
        <s-crud title="Tipo de Observacion"
            :config="config"
            add
            @save="saveDed($event)"
            edit
            remove
            :filter="filter">
            <template scope="props">
                <v-container>
                    <v-row>
                        <v-col>
                            <s-text
								v-model="props.item.TypeObservation"
								label="Descripción"
							></s-text>
                        </v-col>
                        <v-col>
                            <s-select-definition
								v-model="props.item.FopStatus"
								:def="1333"
								label="Estado"
							></s-select-definition>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:FopStatus="{row}">
				<v-chip 
					x-small
					:color="row.FopStatusColor"
				>
					{{row.FopStatusName}}
				</v-chip>
			</template>
        </s-crud>
    </div>
</template>

<script>
import _sFrzTypeObservation from "@/services/FrozenProduction/FrzTypeObservation.js";
export default {
    props:{
        TypeGroup: {
            type: Number,
            default: 0
        },
        TypeSubGroup: {
            type: Number,
            default: 0
        },
    },

    watch:{
        TypeGroup()
        {
            this.Initialize();
        },
        TypeSubGroup()
        {
            this.Initialize();
        }
    },

    data(){
        return {
            filter: {TypeGroup: 0, TypeSubGroup: 0},
            config:{
                service: _sFrzTypeObservation,
                model: {
						FopID: "ID",
						FopStatus: "number"
					},
                headers: [
                    { text: "ID", value: "FopID", width: "5%", align: "end", sorteable: true },
                    { text: "Tipo Observacion", value: "TypeObservation", width: "25%", sorteable: false },               
                    { text: "Grupo", value: "TypeGroupName", width: "25%", sorteable: true },
                    { text: "Sub Grupo", value: "TypeSubGroupName", width: "25%", sorteable: false },                    
                    { text: "Estado", value: "FopStatus", width: "5%", align: "center", sorteable: false },
                ],
            },
            
        }
    },
    methods:{
        Initialize()
        {
            this.filter = { TypeGroup: this.TypeGroup, TypeSubGroup: this.TypeSubGroup }
        },
        saveDed(item) 
        {
            item.TypeGroup = this.TypeGroup;
            item.TypeSubGroup = this.TypeSubGroup;
            //item.SecStatus=1;
            item.UsrCreateID=0;
            
            if (item.TypeObservation.length == 0) {
                this.$fun.alert("Registre descripción de valor", "warning");
                return;
            }
            item.save();       
        },
    },
    created(){
        
    }
}
</script>
