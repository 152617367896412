<template>
    <div>
        <s-crud
            no-border
            title="Sub Grupo"
            :config="config"
            add 
            edit
            remove
            restore
            @save="save($event)"
            :filter="filter"
            @rowSelected="rowSelected($event)" >
            <template scope="props">
                <v-container>
                    <v-row>
                        <v-col>
                            <s-text
								v-model="props.item.FsgName"
								label="Descripción"
							></s-text>
                        </v-col>
                        <v-col>
                            <s-select-definition
								v-model="props.item.FsgStatus"
								:def="1333"
								label="Estado"
							></s-select-definition>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:FsgStatus="{row}">
				<v-chip 
					x-small
					:color="row.FsgStatusColor"
				>
					{{row.FsgStatusName}}
				</v-chip>
			</template>
        </s-crud>
    </div>
</template>

<script>
import _sSubGroupService from "@/services/FrozenProduction/SubGroupService.js";
export default{
    props:{
        TypeGroup:{
            type: Number,
            default: 0
        }
    },
    watch: {
        TypeGroup(){
            this.Initialize();
        }
    },
    data(){
        return {
            filter: {TypeGroup: 0},
            config:{
                service: _sSubGroupService,
                model: {
                    FsgID: "ID",
                    FsgStatus: "number"
                },
                headers: [
                    { text: "ID", value: "FsgID" },
					{ text: "Descripción", value: "FsgName" },
					{ text: "Estado", value: "FsgStatus"},
                ]
            }
        }
    },
    methods:{
        rowSelected(items) {            
            if (items.length > 0) {
                // this.dedValue = items[0].DedValue;
                // this.dedValue1 = items[0].DedID;
                
            }   
            this.$emit("rowSubGroup", items);         
        },
        Initialize()
        {
            this.filter = { TypeGroup: this.TypeGroup }
        },
        save(item){
            if(item.FsgName == ""){
                this.$fun.alert("Ingrese descripción");
                return;
            }
            item.TypeGroup = this.TypeGroup;
            item.save();
        }
    }
}
</script>