<template>
    <v-card>
        <s-toolbar label="Observaciones" dark dense></s-toolbar>
            <v-card-text>
                <v-row>
                    <v-col>                
                        <group-observation
                            @rowSelected="rowSelected($event)"></group-observation>                            
                    </v-col>
                    <v-col>
                        <sub-group
                            :TypeGroup="TypeGroup"
                            @rowSubGroup="rowSubGroup($event)"></sub-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <type-observation
                            :TypeGroup="TypeGroup"
                            :TypeSubGroup="TypeSubGroup">

                        </type-observation>
                    </v-col>
                </v-row>
            </v-card-text>
    </v-card>
</template>

<script>

import GroupObservation from './FrzGroupObservation.vue'; 
import SubGroup from './SubGroupObservation.vue';
import TypeObservation from './FrzTypeObservation.vue';
export default {
		components: {
			GroupObservation, SubGroup, TypeObservation		
		},

		data() {
			return {
				TypeGroup: 0,
                TypeSubGroup: 0
			}
		},

		methods: {
			rowSelected(rows) {
				if(rows.length > 0){
					this.TypeGroup = rows[0].DedValue;  
                    this.TypeSubGroup = 0;                            
				}
			},
            rowSubGroup(rows)
            {
                if(rows.length > 0)
                {
                    this.TypeSubGroup = rows[0].FsgID;                      
                }             
            }
		},
	}
</script>